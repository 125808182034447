<!-- eslint-disable no-param-reassign -->
<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">

      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <span class="brand-logo2">
                <b-img
                  class="brand-text"
                  src="@/assets/images/logo/logocort.png"
                />
              </span>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none mt-3 toggle-color"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>

        </ul>

      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area mt-2"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <li class="px-1">
        <b-form-input
          v-model="filter"
          :class="(!isVerticalMenuCollapsed || isMouseHovered) ? 'visible' : 'invisible'"
          placeholder="Pesquise um item do menu"
        />
      </li>
      <vertical-nav-menu-items
        :items="itemsFiltered"
        :force-open="!!(filter)"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BLink, BImg, BFormInput } from 'bootstrap-vue';
import { provide, computed, ref } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import { $themeConfig } from '@themeConfig';
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
import useVerticalNavMenu from './useVerticalNavMenu';

export default {
    components: {
        VuePerfectScrollbar,
        VerticalNavMenuItems,
        BLink,
        BImg,
        BFormInput,
    },
    props: {
        isVerticalMenuActive: {
            type: Boolean,
            required: true,
        },
        toggleVerticalMenuActive: {
            type: Function,
            required: true,
        },
    },
    setup(props) {
        const {
            isMouseHovered,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            updateMouseHovered,
        } = useVerticalNavMenu(props);

        const { skin } = useAppConfig();

        // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
        const shallShadowBottom = ref(false);

        provide('isMouseHovered', isMouseHovered);

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        };

        const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'));

        // App Name
        const { appName, appLogoImage } = $themeConfig.app;

        return {
            perfectScrollbarSettings,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            isMouseHovered,
            updateMouseHovered,
            collapseTogglerIconFeather,

            // Shadow Bottom
            shallShadowBottom,

            // Skin
            skin,

            // App Name
            appName,
            appLogoImage,
        };
    },

    data() {
        return {
            filter: '',
            navMenuItems: [],
        };
    },

    computed: {
        itemsFiltered() {
            if (!this.filter) {
                return this.navMenuItems;
            }
            function fil(items, filter) {
                const filteredItems = [];
                items.forEach(item => {
                    if (item.title.toLowerCase().includes(filter.toLowerCase())) {
                        if (!filteredItems.some(filteredItem => filteredItem.title === item.title)) {
                            filteredItems.push(item);
                        }
                    }
                    if (item.children && item.children.length > 0) {
                        const filteredChildren = fil(item.children, filter);
                        if (filteredChildren.length > 0) {
                            if (!filteredItems.some(filteredItem => filteredItem.title === item.title)) {
                                filteredItems.push({ ...item, children: filteredChildren });
                            }
                        }
                    }
                });
                return filteredItems;
            }

            return fil(this.navMenuItems, this.filter);
        },
    },

    mounted() {
        this.navMenuItems = this.$store.state.user.data.menu;
    },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.toggle-color{
  color: #fff !important;
  &:hover{
    color: #ddd9ce !important;
  }
}

</style>
