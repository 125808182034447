<template>
  <p class="clearfix mb-0">
    <span class="float-md-right d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <span class="d-none d-sm-inline-block"><b-img
        width="40"
        style="margin-top: -0.3rem; margin-left: 0.5rem"
        src="@/assets/images/logo/logo.png"
      /></span>
    </span>
  </p>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
  },
}
</script>
