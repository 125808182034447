<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            class="icon-nav-color"
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ name }}
            </p>
            <span class="user-status">{{ sector }}</span>
          </div>
          <b-avatar
            size="40"
            class="badge-minimal icon-color"
          />
        </template>
        <b-dropdown-item
          :to="{ name: 'profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
} from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import UserService from '@/service/user/user';
import 'animate.css';

export default {
    components: {
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,

        // Navbar Components
        DarkToggler,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            name: this.$store.state.user.data.name,
            sector: this.$store.state.user.data.sector.name,
        };
    },
    methods: {
        error() {
            this.$swal({
                icon: 'error',

                html: '<span>Falha no Servidor</span> ',
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },
        async logout() {
            this.callLoading(true);
            await UserService.logout();
            sessionStorage.clear();
            window.location.href = '/login';
        },
    },
};
</script>
<style lang="scss">
.navbar-container {
  background: #2E2C6C !important;
}
.user-status {
  color: white !important;
}
.user-name {
  color: white;
}

.icon-color {
  background: #d8dddd !important;
}

.icon-nav-color {
  color: #fff !important;
  &:hover {
    color: #ddd9ce !important;
  }
}
</style>
