<template>
  <li
    v-if="canViewVerticalNavMenuGroup(item)"
    class="nav-item has-sub"
    :class="{
      'open': isOpen,
      'disabled': item.disabled,
      'sidebar-group-active': isActive,
    }"
  >

    <b-link
      class="d-flex align-items-center"
      @click="() => {
        updateGroupOpen(!isOpen)
      }"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse
      v-model="isOpen"
      class="menu-content"
      tag="ul"
    >
      <component
        :is="resolveNavItemComponent(child)"
        v-for="(child, index) in item.children"
        :key="index"
        ref="groupChild"
        :force-open="forceOpen"
        :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from 'bootstrap-vue';
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import { useUtils as useAclUtils } from '@core/libs/acl';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';

// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup';
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup';

export default {
    name: 'VerticalNavMenuGroup',
    components: {
        VerticalNavMenuHeader,
        VerticalNavMenuLink,
        BLink,
        BBadge,
        BCollapse,
    },
    mixins: [mixinVerticalNavMenuGroup],
    props: {
        forceOpen: {
            type: Boolean,
            default: () => false,
        },
        item: {
            type: Object,
            required: true,
        },
    },
    watch: {
        forceOpen() {
            this.isOpen = this.forceOpen;
        },

        item() {
            this.isOpen = this.forceOpen;
        },
    },
    setup(props) {
        const {
            isOpen,
            isActive,
            updateGroupOpen,
            updateIsActive,
        } = useVerticalNavMenuGroup(props.item);

        const { t } = useI18nUtils();
        const { canViewVerticalNavMenuGroup } = useAclUtils();

        return {
            resolveNavItemComponent,
            isOpen,
            isActive,
            updateGroupOpen,
            updateIsActive,

            // ACL
            canViewVerticalNavMenuGroup,

            // i18n
            t,
        };
    },
};
</script>

<style>
</style>
